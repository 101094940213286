import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import PageLead from "../../../components/pageLead";

const CompanyPage = () => {
    return (
        <Layout>
            <div className="company-page">
                <PageLead sub="COMPANY"
                          title="会社概要"
                />
                <section className="information-wrap">
                    <div className="information-table">
                        <div className="row">
                            <div className="column">会社名</div>
                            <div className="value">株式会社OKULAB <br className="sp"/>（英文社名OKULAB,Inc.）</div>
                        </div>
                        <div className="row">
                            <div className="column">代表者名</div>
                            <div className="value">代表取締役CEO 久保田 淳</div>
                        </div>
                        <div className="row">
                            <div className="column">所在地</div>
                            <div className="value">151-0062 東京都 渋谷区 元代々木町4ｰ5</div>
                        </div>
                        <div className="row">
                            <div className="column">設立</div>
                            <div className="value">2016年8月19日</div>
                        </div>
                        <div className="row last">
                            <div className="column">事業内容</div>
                            <div className="value">
                                <ul>
                                    <li>ランドリーサービスFC及び直営展開</li>
                                    <li>洗濯ソリューションサービスの企画・開発・提供</li>
                                    <li>企業アライアンスなどに関するコンサルティング</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="map-wrap">
                    <div className="text-wrap">
                        <h2 className="title">アクセス</h2>
                        <div className="sub">Access</div>
                    </div>
                    <div className="map-content">
                        <iframe
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.355498068577!2d139.6872803153817!3d35.66824763829097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f34c913d1111%3A0x550fc73660b9f922!2z5qCq5byP5Lya56S-T0tVTEFC!5e0!3m2!1sja!2sjp!4v1554729430161!5m2!1sja!2sjp"
                            allowFullScreen></iframe>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export const Head = () => (
    <Seo
        title="会社概要"
        description="会社名：株式会社OKULAB （英文社名OKULAB,Inc.）、代表取締役CEO：久保田淳、所在地：151-0062 東京都渋谷区元代々木町4ｰ5、2016年8月19日設立、コインランドリーFC事業"
    />
)
export default CompanyPage


